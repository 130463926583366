import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "nav",
  class: "pt-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Navbar)
    ]),
    _createVNode(_component_Alert, {
      open: "false",
      title: "Título del Alert",
      message: "Mensaje de prueba",
      type: "danger"
    }),
    _createVNode(_component_router_view),
    _createVNode(_component_Footer, {
      links1_title: _ctx.links1_title,
      links1: _ctx.links1,
      links2_title: _ctx.links2_title,
      links2: _ctx.links2
    }, null, 8, ["links1_title", "links1", "links2_title", "links2"])
  ], 64))
}