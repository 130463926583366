
import { defineComponent } from "vue";

import Slide from "@/components/home/Slide.vue";
import Cards from "@/components/home/Cards.vue";
import MyHistory from "@/components/home/MyHistory.vue";
import Technologies from "@/components/home/Technologies.vue";
import Presentation from "@/components/home/Presentation.vue";
import Skills from "@/components/home/Skills.vue";
import HomeLinks from "@/components/home/HomeLinks.vue";
import SocialLinks from "@/components/SocialLinks.vue";

export default defineComponent({
  name: "Home",
  components: {
    Slide,
    Cards,
    MyHistory,
    Technologies,
    Presentation,
    Skills,
    HomeLinks,
    SocialLinks,
  },
});
