import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page page-home" }
const _hoisted_2 = { class: "slide bg-white my-3" }
const _hoisted_3 = { class: "container items-center max-w-6xl mx-auto" }
const _hoisted_4 = { class: "flex flex-wrap items-center" }
const _hoisted_5 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Technologies = _resolveComponent("Technologies")!
  const _component_MyHistory = _resolveComponent("MyHistory")!
  const _component_Cards = _resolveComponent("Cards")!
  const _component_Presentation = _resolveComponent("Presentation")!
  const _component_Skills = _resolveComponent("Skills")!
  const _component_HomeLinks = _resolveComponent("HomeLinks")!
  const _component_SocialLinks = _resolveComponent("SocialLinks")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Slide)
          ])
        ])
      ])
    ]),
    _createVNode(_component_Technologies),
    _createVNode(_component_MyHistory),
    _createVNode(_component_Cards),
    _createVNode(_component_Presentation),
    _createVNode(_component_Skills),
    _createVNode(_component_HomeLinks),
    _createVNode(_component_SocialLinks)
  ]))
}