
import { ref, defineComponent } from 'vue';

export default defineComponent({
    name: 'Alert',

    props: {
        open : {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'primary'
        },
        title: {
            type: String,
            default: null
        }
    },
  
    setup(props) {
        const isOpen  = ref(props.open);

        const closeAlert = () => {
            isOpen.value = false;
        }

        return {
            isOpen,
            closeAlert,
        }
    }
});
