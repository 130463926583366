
import { ref, defineComponent } from "vue";

export default defineComponent({
  name: "Navbar",

  setup() {
    const menuSelected = ref("Home");
    const mobileMenuIsOpen = ref(false);
    const links = ref({
      Home: "/",
      Projects: "/projects",
      Jobs: "/jobs",
      Collaborations: "/collaborations",
      Hobbies: "/hobbies",
      Services: "/services",
      About: "/about",
      Contac: "/contact",
    });

    /**
     * Conmuta el estado actual del menú para móviles.
     */
    const toggleMobileMenu = () => {
      mobileMenuIsOpen.value = !mobileMenuIsOpen.value;
    };

    /**
     * Cierra el menú para móviles.
     */
    const closeMobileMenu = () => {
      mobileMenuIsOpen.value = false;
    };

    /**
     * Selecciona una opción del menú.
     */
    const selectMenu = (name: string) => {
      closeMobileMenu();

      menuSelected.value = name;
    };

    return {
      menuSelected,
      links,
      mobileMenuIsOpen,

      // functions
      toggleMobileMenu,
      closeMobileMenu,
      selectMenu,
    };
  },

  //toggle → box-nav-menu-mobile
});
