
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Footer",
  props: {
    policy: {
      type: String,
      default: null,
    },

    terms: {
      type: String,
      default: null,
    },
  },

  setup() {
    const store = useStore();
    let fetchApi = () => store.dispatch("site/fetchApi");

    fetchApi();

    return {
      platforms: computed(() => store.state.site.platforms),
      technologies: computed(() => store.state.site.technologies),
      resources: computed(() => store.state.site.resources),
    };
  },
});
