
import { defineComponent } from "vue";

export default defineComponent({
  name: "MyHistory",

  setup() {
    const startDeveloping = () => {
      const from = new Date(2018, 11, 16);
      const now = new Date();

      // Diferencia de tiempo en segundos.
      let diff = Math.abs(now.getTime() - from.getTime()) / 1000;

      // Años
      let years = diff > 31536000 ? Math.floor(diff / 31536000) : 0;
      diff -= years * 31536000;

      // Meses
      let months = diff > 2629800 ? Math.floor(diff / 2629800) : 0;
      diff -= months * 2629800;

      return years + " años, " + months + " meses";
    };

    return {
      startDeveloping,
    };
  },
  /*
    methods: {
      
    },
    */
});
